import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import listPlugin from "@fullcalendar/list"
import { formatDate } from "../utils/formatDate"
import CalendarLegend from "../components/CalendarLegend"

class CalendarPage extends React.Component {
  constructor(props) {
    super(props)
    this.handleEventClick = this.handleEventClick.bind(this)
  }

  handleEventClick(e) {
    if (e.event.extendedProps.location === "The Cinematheque") {
      window.open(
        "http://thecinematheque.ca" + e.event.extendedProps.description
      )
    } else if (e.event.extendedProps.location === "Vancity Theatre") {
      window.open(
          e.event.extendedProps.description
      )
    } else if (
      e.event.extendedProps.location === "Fifth Avenue Cinema" ||
      e.event.extendedProps.location === "International Village" ||
      e.event.extendedProps.location === "Scotiabank Theatre"
    ) {
      var cineplexUrlSlug = e.event.title
        .replace(/ *\([^)]*\) */g, "")
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "")
      window.open("https://www.cineplex.com/Movie/" + cineplexUrlSlug)
    } else if (e.event.extendedProps.location.includes("Rio")) {
      var urlSlug = e.event.title
        .replace(/ *\(.*$/g, "")
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "")
      window.open("https://riotheatre.ca/movie/" + urlSlug)
    }
  }

  render() {
    const colors = {
      themeOne: {
        rio: "#a2b9bc",
        viff: "#6b5b95",
        pac: "#878f99",
      },
      themeTwo: {
        rio: "#99BCBC",
        viff: "#f5e1ee",
        pac: "#580000",
        fifth: "khaki",
        tinsel: "dimgray",
        scotia: "olive"
      },
    }
    const rioDates = formatDate(this.props.data.rio.edges)
    const viffDates = formatDate(this.props.data.viff.edges)
    const cinemathequeDates = formatDate(this.props.data.cinematheque.edges)
    const fifthDates = formatDate(this.props.data.fifth.edges)
    const tinselDates = formatDate(this.props.data.tinsel.edges)
    const scotiaDates = formatDate(this.props.data.scotia.edges)

    return (
      <Layout>
        <SEO
          title="Calendar listings"
          keywords={[
            `cinema`,
            `listings`,
            `Vancouver`,
            `movies`,
            `film`,
            `The Cinematheque`,
            `The Rio`,
            `Vancity Theatre`,
            `calendar`,
          ]}
        />
        <CalendarLegend />
        {typeof window !== "undefined" && (
          <FullCalendar
            initialView="list"
            plugins={[listPlugin, dayGridPlugin]}
            height={"auto"}
            showNonCurrentDates={false}
            eventClick={this.handleEventClick}
            eventDisplay={'block'}
            eventBorderColor={'#fff'}
            views={{
              list: {
                duration: { days: 30 },
              },
            }}
            headerToolbar={{
              left: "today prev,next",
              center: "title",
              right: "listMonth,dayGridWeek,dayGridMonth",
            }}
            eventSources={[
              {
                events: viffDates,
                color: colors.themeTwo.viff,
                textColor: "#444",
                className: "vancity",
              },
              {
                events: cinemathequeDates,
                color: colors.themeTwo.pac,
                className: "cinematheque",
              },
              {
                events: rioDates,
                color: colors.themeTwo.rio,
                className: "rio",
              },
              {
                events: fifthDates,
                color: colors.themeTwo.fifth,
                className: "fifth",
              },
              {
                events: tinselDates,
                color: colors.themeTwo.tinsel,
                className: "tinsel",
              },
              {
                events: scotiaDates,
                color: colors.themeTwo.scotia,
                className: "scotia",
              },
            ]}
          />
        )}
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query($currentDate: Date) {
    rio: allIcal(
      filter: {
        location: { regex: "/Rio/" }
        summary: {
          ne: "Private Event"
          regex: "/^(?:(?!Present|Improv|Burlesque|Show|Tour|Evening|Band|Live|Fundraiser|Savage).)*$/"
        }
      }
    ) {
      edges {
        node {
          summary
          start
          location
          description
        }
      }
    }
    viff: allIcal(
      filter: { location: { regex: "/Vancity/" } }
    ) {
      edges {
        node {
          summary
          start
          location
          description
        }
      }
    }
    fifth: allIcal(
      filter: { location: { regex: "/Fifth/" } }
    ) {
      edges {
        node {
          summary
          start
          location
          description
        }
      }
    }
    tinsel: allIcal(
      filter: {
        location: { regex: "/International/" }
      }
    ) {
      edges {
        node {
          summary
          start
          location
        }
      }
    }
    scotia: allIcal(
      filter: {
        start: { gt: $currentDate }
        location: { regex: "/Scotia/" }
      }
    ) {
      edges {
        node {
          summary
          start
          location
        }
      }
    }
    cinematheque: allIcal(
      filter: {
        location: { regex: "/Cinematheque/" }
        summary: { ne: "Rental Event" }
      }
    ) {
      edges {
        node {
          summary
          start
          location
          description
        }
      }
    }
  }
`

export default CalendarPage
